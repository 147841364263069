@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-background text-text;
}

h1 {
  @apply text-xl text-primary font-bold;
}

a {
  @apply font-bold underline text-primary;
}

input[type="text"], input[type="email"], textarea {
  @apply w-full rounded-3xl border-primary border-2 px-6 py-2 bg-background;

  &:focus {
    @apply outline-0
  }
}

input[type="radio"] {
  @apply border-2 border-primary bg-transparent text-primary disabled:border-disabled disabled:text-disabled;
}

button {
  @apply border border-primary rounded-3xl border-2 py-2 px-6 text-primary underline w-fit transition;

  &:hover {
    @apply bg-primary text-white;
  }
}

.filter-white:hover {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(334deg) brightness(106%) contrast(101%);
}

select{
  appearance: none;
  outline: 0;
  box-shadow: none;

  @apply border border-primary rounded-3xl border-2 py-2 px-6 text-primary bg-transparent text-center;
}
